import React, { Fragment } from "react";
import { useRef, useState } from "react";
import RiseLoader from "react-spinners/RiseLoader";
import moment from "moment";
import { Link } from "react-router-dom";
import { Menu, Transition, Dialog } from "@headlessui/react";
import Swal from "sweetalert2";
import axios from "axios";
import Checkbox from "./subComponents/checkbox";
import { NavLink } from "react-router-dom";
import emptySign from "../../Assets/emptySign.svg";
import { useSelector } from "react-redux";
import { UserRole } from "../../Constants/users";
export const handleDeleteAd = async (e, id, removeCar) => {
  e.stopPropagation();
  const config = {
    method: "post",
    url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/deleteAd/${id}`,
    headers: {
      "x-auth-token": `${localStorage.getItem("token")}`,
    },
  };

  axios(config)
    .then(() => {
      removeCar(id);
      Swal.fire({
        title: "Suprimer!",
        text: "Annonce supprimer avec succée!",
        iconColor: "#4368B1",
        icon: "success",
        confirmButtonColor: "#4368B1",
        confirmButtonText: "Finir",
      });
    })
    .catch(() => {
      Swal.fire({
        text: "fonctionalité en maintenance",
        icon: "warning",
        iconColor: "#4368B1",
        confirmButtonColor: "#4368B1",
        confirmButtonText: "quitter",
      });
    });
};
const WebCarDisplay = ({
  carData,
  isGenerateAdActive,
  removeItem,
  loading,
  mode,
  handleChooseCar,
  chosenCar,
}) => {
  const users = useSelector((state) => state.users).users;
  const [open, setOpen] = useState(false);
  const [singleAd, setSingleAd] = useState([]);
  const [checkbox, setCheckBox] = useState("");
  const [areSingleAdsLoading, setSingleAdsLoading] = useState(true);
  const handleCheckbox = (e) => {
    let carList = [];
    carList.push(...checkbox, e.target.id);
    setCheckBox(carList);
  };
  const riseLoaderCss = {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-100px",
  };

  const cancelButtonRef = useRef(null);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const getSingleCar = async (id) => {
    // I THINK THIS COULD BE DONE IN A DIFFERENT WAY
    try {
      const singleAdData = await axios.get(
        `${process.env.REACT_APP_CAR_SERVICE}/api/car/${id}`,
        { headers: { "x-auth-token": `${localStorage.getItem("token")}` } }
      );
      setOpen(true);
      setSingleAd(singleAdData.data);

      setSingleAdsLoading(false);
    } catch (error) {
      Swal.fire(error.response);
      setSingleAdsLoading(false);
    }
  };

  const handleCarAction = (car) => {
    if (mode === "list" && !isGenerateAdActive) {
      return getSingleCar(car._id);
    } else {
      return handleChooseCar(car);
    }
  };

  const isCarSelected = (car) => {
    if (mode === "PICK_ONE") {
      return chosenCar?._id === car._id;
    } else if (mode === "PICK_MULTIPLE") {
      return chosenCar?.some((c) => c._id === car._id);
    }
    return false;
  };

  const Skeleton = () => {
    return <div className="bg-[#f0f0f0] h-12 rounded animate-pulse m-2" />;
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto ">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white0 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h2 className="p-3 ">Detailles de l'annonce</h2>

                        <div className="mt-2">
                          {areSingleAdsLoading ? (
                            <RiseLoader
                              cssOverride={riseLoaderCss}
                            ></RiseLoader>
                          ) : (
                            <table>
                              <tbody>
                                <tr>
                                  <td className="p-3 text-grey1">Modèle</td>
                                  <td>{singleAd.make.name}</td>
                                </tr>
                                <tr>
                                  <td className="p-3 text-grey1">Année</td>
                                  <td className="p-3">{singleAd.model.name}</td>
                                </tr>
                                <tr>
                                  <td className="p-3 text-grey1">
                                    Propriétaire
                                  </td>
                                  <td className="p-3">
                                    {singleAd.ownerNumber}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-3 text-grey1">Date</td>
                                  <td className="p-3">{singleAd.createdAt}</td>
                                </tr>
                                <tr>
                                  <td className="p-3 text-grey1">Prix</td>
                                  <td className="p-3">{singleAd.price}</td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex justify-center  sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-blue1 bg-white px-4 py-2 text-base font-medium text-darkblue1 shadow-sm hover:bg-darkblue1 hover:text-white0 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {!loading && carData.length === 0 ? (
        <div className="grid justify-items-center items-center md:h-2/3 ">
          <div className="flex flex-col ">
            <img src={emptySign} alt="empty-sign" />
            <p className="text-[18px] font-bold">Pas encore de voitures</p>
            {(users?.role === UserRole.OWNER ||
              users?.role === UserRole.ADMIN ||
              users?.role === UserRole.SUPER_ADMIN) && (
              <NavLink
                className={
                  " text-[16px] mx-3 bg-warning1 p-2 text-center text-white0"
                }
                to="/addCar"
              >
                + Ajouter
              </NavLink>
            )}
          </div>
        </div>
      ) : (
        <table className="min-w-full divide-y divide-grey2 dark:divide-grey1 table-auto">
          <thead>
            <tr>
              <th className=" text-left text-xs font-medium text-gray-500 uppercase"></th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px] ">
                Marque
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                Modèle
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                Année
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1 capitalize md:text-[14px]">
                Propriétaire
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1  capitalize md:text-[14px]">
                Date
              </th>
              <th className="px-6 py-3 text-left text-xs font-mulish text-grey1  capitalize md:text-[14px]">
                Prix
              </th>
            </tr>
          </thead>
          <tbody>
            {loading &&
              Array(5)
                .fill("")
                .map((_, index) => (
                  <tr key={index}>
                    <td colSpan={7}>
                      <Skeleton />
                    </td>
                  </tr>
                ))}
            {!loading &&
              carData.map((car, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => handleCarAction(car)}
                    className={`cursor-pointer text-black1 ${
                      isCarSelected(car)
                        ? "hover:bg-error1 bg-darkblue1"
                        : "hover:bg-grey3 bg-white0"
                    }`}
                  >
                    <td>
                      {(isGenerateAdActive && users?.role === UserRole.OWNER) ||
                      users?.role === UserRole.ADMIN ||
                      users?.role === UserRole.SUPER_ADMIN ? (
                        <div className="flex flex-row items-center ">
                          <Checkbox
                            onChange={handleCheckbox}
                            value={checkbox}
                            id={car._id}
                          ></Checkbox>
                        </div>
                      ) : null}
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className=" whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold">
                        {car?.make.name}
                      </p>
                      <p className="whitespace-no-wrap text-[12px] md:text-[12px] font-mulish  text-grey4">
                        {"Mise a jours " +
                          moment(car?.updatedAt).startOf("minutes").fromNow()}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap   text-[14px] md:text-[14px] font-mulish font-semibold">
                        {car?.model.name}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-spacing-0 border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold">
                        {car?.overviewYear}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-spacing-0 border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap border-spacing-0 text-[14px] md:text-[14px] font-mulish font-semibold">
                        {car?.ownerNumber}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold uppercase">
                        {moment(car.createdAt).format("MMM DD, YYYY")}
                      </p>
                      <p className="whitespace-no-wrap text-[14px] md:text-[14px] font-mulish  text-grey2">
                        {moment(car.createdAt).format("h:mm A")}
                      </p>
                    </td>
                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <p className="whitespace-no-wrap  text-[14px] md:text-[14px] font-mulish font-semibold">
                        {car?.price}
                      </p>
                    </td>

                    <td className="px-5 py-5 border-b border-grey3 bg-white text-sm ">
                      <Menu as="div" className="flex flex-row text-left ">
                        <Menu.Button className="  justify-center rounded-md  px-4 text-white0 ">
                          <Link to={`/viewCar/${car._id}`}>
                            {" "}
                            <svg
                              width="15"
                              height="13"
                              viewBox="0 0 15 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.875 1.5V9H13.125V1.5H1.875ZM1.875 0.25H13.125C13.4565 0.25 13.7745 0.381696 14.0089 0.616116C14.2433 0.850537 14.375 1.16848 14.375 1.5V9C14.375 9.33125 14.2437 9.65 14.0063 9.88125C13.775 10.1187 13.4563 10.25 13.125 10.25H8.75V11.5H10V12.75H5V11.5H6.25V10.25H1.875C1.54375 10.25 1.225 10.1187 0.99375 9.88125C0.75625 9.65 0.625 9.33125 0.625 9V1.5C0.625 0.80625 1.18125 0.25 1.875 0.25ZM6.775 4.58125C6.96875 4.39375 7.23125 4.28125 7.5 4.28125C7.76875 4.2875 8.03125 4.39375 8.225 4.5875C8.4125 4.775 8.525 5.0375 8.525 5.30625C8.525 5.58125 8.4125 5.8375 8.225 6.03125C8.03125 6.225 7.76875 6.33125 7.5 6.33125C7.23125 6.33125 6.96875 6.21875 6.775 6.03125C6.5875 5.8375 6.475 5.58125 6.475 5.30625C6.475 5.0375 6.5875 4.775 6.775 4.58125ZM6.29375 6.5C6.44996 6.66227 6.63731 6.79135 6.84457 6.87952C7.05184 6.96768 7.27476 7.01313 7.5 7.01313C7.72524 7.01313 7.94816 6.96768 8.15543 6.87952C8.36269 6.79135 8.55004 6.66227 8.70625 6.5C9.025 6.1875 9.20625 5.75625 9.20625 5.30625C9.20625 4.85625 9.025 4.41875 8.70625 4.1C8.3875 3.78125 7.95 3.6 7.5 3.6C7.05 3.6 6.6125 3.78125 6.29375 4.1C5.975 4.41875 5.79375 4.85625 5.79375 5.30625C5.79375 5.75625 5.975 6.1875 6.29375 6.5ZM3.75 5.30625C4.04617 4.55293 4.56227 3.90621 5.23111 3.45029C5.89995 2.99436 6.69055 2.75034 7.5 2.75C9.20625 2.75 10.6625 3.8125 11.25 5.30625C10.9559 6.06102 10.4404 6.70913 9.77105 7.16538C9.10174 7.62162 8.31002 7.86465 7.5 7.8625C5.79375 7.8625 4.3375 6.8125 3.75 5.30625Z"
                                fill="#4368B1"
                              />
                            </svg>
                          </Link>
                        </Menu.Button>{" "}
                        {users?.role === UserRole.MEMBER ? null : (
                          <>
                            <Menu.Button
                              onClick={(e) => e.stopPropagation()}
                              className="inline-flex w-full justify-center  bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                            >
                              <svg
                                width="4"
                                height="16"
                                viewBox="0 0 4 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z"
                                  fill="#9FA2B4"
                                />
                              </svg>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white0 shadow-lg  focus:outline-none drop-shadow-md">
                                <div className="py-1">
                                  <Menu.Item
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {({ active }) => (
                                      <Link
                                        to={`/editCar/${car._id}`}
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        <div className="flex flex-row p-2 items-center ">
                                          <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M14.4688 13.6953H1.53125C1.22012 13.6953 0.96875 13.9467 0.96875 14.2578V14.8906C0.96875 14.968 1.03203 15.0312 1.10938 15.0312H14.8906C14.968 15.0312 15.0312 14.968 15.0312 14.8906V14.2578C15.0312 13.9467 14.7799 13.6953 14.4688 13.6953ZM3.52988 12.2188C3.56504 12.2188 3.6002 12.2152 3.63535 12.21L6.59199 11.6914C6.62715 11.6844 6.66055 11.6686 6.68516 11.6422L14.1365 4.19082C14.1528 4.17456 14.1657 4.15524 14.1746 4.13398C14.1834 4.11271 14.1879 4.08992 14.1879 4.06689C14.1879 4.04387 14.1834 4.02108 14.1746 3.99981C14.1657 3.97855 14.1528 3.95923 14.1365 3.94297L11.215 1.01973C11.1816 0.986328 11.1377 0.96875 11.0902 0.96875C11.0428 0.96875 10.9988 0.986328 10.9654 1.01973L3.51406 8.47109C3.4877 8.49746 3.47187 8.5291 3.46484 8.56426L2.94629 11.5209C2.92919 11.6151 2.9353 11.712 2.96409 11.8033C2.99288 11.8945 3.04349 11.9774 3.11152 12.0447C3.22754 12.1572 3.37344 12.2188 3.52988 12.2188Z"
                                              fill="blue"
                                            />
                                          </svg>

                                          <p className="ml-2">Modifier</p>
                                        </div>
                                      </Link>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item
                                    onClick={(e) =>
                                      handleDeleteAd(e, car._id, removeItem)
                                    }
                                  >
                                    {({ active }) => (
                                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                      <a
                                        href="#"
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "block px-4 py-2 text-sm"
                                        )}
                                      >
                                        <div className="flex flex-row p-2 items-center">
                                          <svg
                                            width="14"
                                            height="16"
                                            viewBox="0 0 14 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M13.1875 3.5H10.9375V2.09375C10.9375 1.47324 10.433 0.96875 9.8125 0.96875H4.1875C3.56699 0.96875 3.0625 1.47324 3.0625 2.09375V3.5H0.8125C0.501367 3.5 0.25 3.75137 0.25 4.0625V4.625C0.25 4.70234 0.313281 4.76562 0.390625 4.76562H1.45234L1.88652 13.959C1.91465 14.5584 2.41035 15.0312 3.00977 15.0312H10.9902C11.5914 15.0312 12.0854 14.5602 12.1135 13.959L12.5477 4.76562H13.6094C13.6867 4.76562 13.75 4.70234 13.75 4.625V4.0625C13.75 3.75137 13.4986 3.5 13.1875 3.5ZM9.67188 3.5H4.32812V2.23438H9.67188V3.5Z"
                                              fill="red"
                                            />
                                          </svg>
                                          <p className="ml-2">Supprimer</p>
                                        </div>
                                      </a>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default WebCarDisplay;
