import React from "react";

import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Sep 1",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },

  {
    name: "Sep 2",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Sep 4",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Sep 5",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Sep 6",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Chart = () => (
  <div className="bg-white p-4 rounded-lg shadow rounded-md flex-grow bg-white0 border border-grey2  lg:px-9 m-1">
    <div className="flex justify-between text-center h-[30px] py-5 mb-10">
      <div>
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="36" height="36" rx="8" fill="#F7F8FC" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.4189 21.732C27.4189 25.31 25.3099 27.419 21.7319 27.419H13.9499C10.3629 27.419 8.24988 25.31 8.24988 21.732V13.932C8.24988 10.359 9.56388 8.25 13.1429 8.25H15.1429C15.8609 8.251 16.5369 8.588 16.9669 9.163L17.8799 10.377C18.3119 10.951 18.9879 11.289 19.7059 11.29H22.5359C26.1229 11.29 27.4469 13.116 27.4469 16.767L27.4189 21.732Z"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M13.481 20.4629H22.216"
            stroke="#282F75"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="flex">
        <hr
          style={{
            border: "solid",
            marginLeft: "25px",
            paddingRight: "25px",
            marginTop: "10px",
            marginRight: "15px",
            borderColor: "#282F75",
          }}
        />

        <p
          style={{
            justifyContent: "center",
            paddingRight: "15px",
            color: "#9FA2B4",
            textAlign: "center",
          }}
        >
            Cette semaine
        </p>
        <hr
          style={{
            border: "solid",
            paddingRight: "25px",
            marginTop: "10px",
            marginRight: "15px",
            borderColor: "#9FA2B4",
            marginLeft: "25px",
          }}
        />
        <p style={{ color: "#9FA2B4" }}>La semaine précédente</p>
        <div className="flex">
          <p
            style={{
              color: "#BEC0CA",
              marginLeft: "35px",
              marginRight: "10px",
            }}
          >
              Cette semaine
          </p>

          <svg
            className="w-4 h-7 "
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6L8 10L12 6"
              stroke="#BEC0CA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <p className="text-sm text-[#8B8D97] mb-4">Taux de rotation des stocks</p>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={600}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barSize={20}
      >
        <XAxis
          axisLine={false}
          tickLine={false}
          dataKey="name"
          scale="point"
          padding={{ left: 50, right: 50 }}
          stroke="#9FA2B4"
        />
        <YAxis axisLine={false} tickLine={false} stroke="#9FA2B4" />

        <Bar
          dataKey="pv"
          fill="#282F75"
          background={{ fill: "#eff0fa", radius: 10 }}
          radius={[10, 10, 10, 10]}
        />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default Chart;
