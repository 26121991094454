import { useEffect, useState } from "react";
import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import MobileCarDisplay from "./mobileCarDisplay";
import WebCarDisplay from "./WebCarDisplay";
import RiseLoader from "react-spinners/RiseLoader";
import { useSelector, useDispatch } from "react-redux";
import { getCarCount } from "../../Redux/cars/actions";
import { UserRole } from "../../Constants/users";
import BottomNav from "../notifications/subComponents/bottomNab";
import * as type from "../../Redux/cars/actionTypes";

const CarsDisplay = ({
  handleChooseCar,
  mode = "list",
  customTitle,
  customChooseButton,
}) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users).users;
  const user = useSelector((state) => state.profile).profile;
  const cars = useSelector((state) => state.cars).cars;
  const [filteredCars, setFilteredCars] = useState(cars);
  const totalCarsCount = useSelector((state) => state.cars).totalCarsCount;
  const id = user._id;

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [checkbox, setCheckBox] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [isGenerateAdActive, setIsGenerateAdActive] = useState(false);
  const isLastPage = (page, totalPages) => page === totalPages;
  const totalPages = Math.ceil(totalCarsCount / pageSize);
  const isLast = isLastPage(page, totalPages);
  const [chosenCar, setChosenCar] = useState(
    mode === "PICK_MULTIPLE" ? [] : {}
  );
  const riseLoaderCss = {
    position: "fixed",
    top: "50%",
    left: "50%",
    marginTop: "-50px",
    marginLeft: "-100px",
  };
  const handleCheckbox = (e) => {
    let carList = [];
    carList.push(...checkbox, e.target.id);
    setCheckBox(carList);
  };
  const handleSubmit = async () => {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/publishCars`,
      headers: {
        "x-auth-token": `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: checkbox,
    };

    await axios(config)
      .then(() => {
        Swal.fire("success", "Votre voiture a éte publier");
      })
      .catch(() => {
        // To enhance
        Swal.fire({
          text: "fonctionalité en maintenance",
          icon: "warning",
          iconColor: "#4368B1",
          confirmButtonColor: "#4368B1",
          confirmButtonText: "quitter",
        });
      });
  };
  const getCars = async (pageSize, page) => {
    setIsLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_AUTH_SERVICE}/api/user/`,
      {
        "x-auth-token": `${localStorage.getItem("token")}`,
      }
    );

    const data = [];

    if (user?.role === "owner") {
      res?.data?.filter((e) => e.userId === id).map((e) => data.push(e?._id));
    } else if (user?.role === "member") {
      data.push(user?.userId);
    } else return;

    var config = {
      method: "get",
      url: `${process.env.REACT_APP_CAR_SERVICE}/api/car/getUserAds?pageSize=${pageSize}&page=${page}&userIds=${data}`,
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
    };
    try {
      const getCars = await axios(config).catch(function (error) {
        // to enhance
      });
      dispatch({
        type: "GET_CARS",
        cars: getCars.data.cars,
      });
    } catch (error) {
      Swal.fire(error.response);
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  const removeCars = (id) => {
    const removedCarIndex = cars.findIndex((item) => item._id === id);
    if (removedCarIndex > -1) {
      cars.splice(removedCarIndex, 1);
    }
    setFilteredCars([...cars]);
  };

  useEffect(() => {
    getCars(pageSize, page);
    dispatch(getCarCount());
    // eslint-disable-next-line
  }, [id, pageSize, page]);

  useEffect(() => {
    setFilteredCars(cars);
  }, [cars]);

  const preChooseCar = (car) => {
    setChosenCar((prevCar) => {
      if (mode === "PICK_ONE") {
        if (prevCar?._id === car._id) {
          return null;
        } else {
          return car;
        }
      } else if (mode === "PICK_MULTIPLE") {
        const isCarSelected = prevCar?.some((c) => c._id === car._id);
        if (isCarSelected) {
          return prevCar?.filter((c) => c._id !== car._id);
        } else {
          return [...prevCar, car];
        }
      }
    });
  };

  return (
    <>
      {mode === "list" && (
        <h3 className="font-bold text-[24px] mb-6 hidden md:block">Voitures</h3>
      )}

      <div className="flex flex-col sm:border  sm:border-grey2 flex-grow bg-white0 sm:w-full sm:rounded-lg overflow-hidden  ">
        {mode === "list" ? (
          <div
            className={
              "flex justify-between items-center space-x-6 px-3 py-3 md:py-6"
            }
          >
            {(isGenerateAdActive && users?.role === UserRole.OWNER) ||
            users?.role === UserRole.ADMIN ||
            users?.role === UserRole.SUPER_ADMIN ? (
              <div className="flex justify-start items-center ">
                <h2 className={"font-bold  text-[19px] "}>Voitures - </h2>

                <p className={"  text-warning1"}> Genérer une annonce</p>
              </div>
            ) : (
              <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-2">
                  <h1 className={"font-bold  text-[19px] "}>Voitures</h1>
                  {user.role !== "member" && (
                    <NavLink
                      onClick={() =>
                        dispatch({
                          type: type.GET_CAR,
                          car: null,
                        })
                      }
                      className="font-bold text-center text-[12px] md:text-[16px] text-warning1 "
                      to="/addCar"
                    >
                      + Ajouter une voiture
                    </NavLink>
                  )}
                </div>
                {cars && isGenerateAdActive && (
                  <button
                    onClick={() => setIsGenerateAdActive(!isGenerateAdActive)}
                    className="p-2 mx-2   text-center text-[12px] md:text-[16px] rounded bg-blue1 text-white0"
                  >
                    Annuler
                  </button>
                )}
              </div>
            )}
            {/* Publish Button WEB */}
            {isGenerateAdActive ? (
              <div className="hidden md:flex lg:flex  ">
                <button
                  onClick={() => setIsGenerateAdActive(!isGenerateAdActive)}
                  className="p-2 mx-2   text-center  rounded bg-blue1 text-white0"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleSubmit()}
                  className="p-2  mx-2  text-center  rounded bg-blue1 text-white0"
                >
                  Publier
                </button>
              </div>
            ) : (
              <div className="lg:flex md:flex xxl:flex hidden">
                {users?.role === UserRole.OWNER ||
                  users?.role === UserRole.ADMIN ||
                  (users?.role === UserRole.SUPER_ADMIN && (
                    <button
                      className="p-2   text-center  rounded bg-blue1 text-white0"
                      onClick={() => setIsGenerateAdActive(!isGenerateAdActive)}
                    >
                      Genérer une annonce
                    </button>
                  ))}
              </div>
            )}
            {/* Publish Button WEB */}
          </div>
        ) : (
          <div className="flex justify-between items-center p-5">
            <div>
              <h5 className="font-bold text-[20px]">{customTitle ?? "Cars"}</h5>
            </div>
            <div className="flex gap-4 items-center justify-center">
              <div className="flex items-center gap-1 justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="6.5"
                    cy="6.5"
                    r="5.75"
                    stroke="#C5C7CD"
                    stroke-width="1.5"
                  />
                  <path
                    d="M11 11L15 15"
                    stroke="#C5C7CD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>

                <input
                  className="w-[100px] placeholder:text-[#4B506D] focus:outline-none"
                  placeholder="Rechercher"
                />
              </div>
              <div className="flex items-center gap-1 justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.12857 4.26839L3.31563 2.12548C3.48647 1.95815 3.7636 1.9582 3.93434 2.12548L6.12132 4.26839C6.39667 4.53812 6.20059 5.00001 5.81195 5.00001H4.5V13.5714C4.5 13.8081 4.30414 14 4.0625 14H3.1875C2.94586 14 2.75 13.8081 2.75 13.5714V5.00001H1.43794C1.04853 5.00001 0.853791 4.53758 1.12857 4.26839ZM7.5625 3.7143H14.5625C14.8041 3.7143 15 3.52243 15 3.28573V2.42858C15 2.19188 14.8041 2.00001 14.5625 2.00001H7.5625C7.32086 2.00001 7.125 2.19188 7.125 2.42858V3.28573C7.125 3.52243 7.32086 3.7143 7.5625 3.7143ZM7.125 6.71429V5.85715C7.125 5.62045 7.32086 5.42858 7.5625 5.42858H12.8125C13.0541 5.42858 13.25 5.62045 13.25 5.85715V6.71429C13.25 6.951 13.0541 7.14287 12.8125 7.14287H7.5625C7.32086 7.14287 7.125 6.951 7.125 6.71429ZM7.125 13.5714V12.7143C7.125 12.4776 7.32086 12.2857 7.5625 12.2857H9.3125C9.55414 12.2857 9.75 12.4776 9.75 12.7143V13.5714C9.75 13.8081 9.55414 14 9.3125 14H7.5625C7.32086 14 7.125 13.8081 7.125 13.5714ZM7.125 10.1429V9.28572C7.125 9.04901 7.32086 8.85715 7.5625 8.85715H11.0625C11.3041 8.85715 11.5 9.04901 11.5 9.28572V10.1429C11.5 10.3796 11.3041 10.5714 11.0625 10.5714H7.5625C7.32086 10.5714 7.125 10.3796 7.125 10.1429Z"
                    fill="#C5C7CD"
                  />
                </svg>
                <span className="text-[#4B506D]">Filtrer</span>
              </div>
              <div className="flex items-center gap-1 justify-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.12857 4.26839L3.31563 2.12548C3.48647 1.95815 3.7636 1.9582 3.93434 2.12548L6.12132 4.26839C6.39667 4.53812 6.20059 5.00001 5.81195 5.00001H4.5V13.5714C4.5 13.8081 4.30414 14 4.0625 14H3.1875C2.94586 14 2.75 13.8081 2.75 13.5714V5.00001H1.43794C1.04853 5.00001 0.853791 4.53758 1.12857 4.26839ZM7.5625 3.7143H14.5625C14.8041 3.7143 15 3.52243 15 3.28573V2.42858C15 2.19188 14.8041 2.00001 14.5625 2.00001H7.5625C7.32086 2.00001 7.125 2.19188 7.125 2.42858V3.28573C7.125 3.52243 7.32086 3.7143 7.5625 3.7143ZM7.125 6.71429V5.85715C7.125 5.62045 7.32086 5.42858 7.5625 5.42858H12.8125C13.0541 5.42858 13.25 5.62045 13.25 5.85715V6.71429C13.25 6.951 13.0541 7.14287 12.8125 7.14287H7.5625C7.32086 7.14287 7.125 6.951 7.125 6.71429ZM7.125 13.5714V12.7143C7.125 12.4776 7.32086 12.2857 7.5625 12.2857H9.3125C9.55414 12.2857 9.75 12.4776 9.75 12.7143V13.5714C9.75 13.8081 9.55414 14 9.3125 14H7.5625C7.32086 14 7.125 13.8081 7.125 13.5714ZM7.125 10.1429V9.28572C7.125 9.04901 7.32086 8.85715 7.5625 8.85715H11.0625C11.3041 8.85715 11.5 9.04901 11.5 9.28572V10.1429C11.5 10.3796 11.3041 10.5714 11.0625 10.5714H7.5625C7.32086 10.5714 7.125 10.3796 7.125 10.1429Z"
                    fill="#C5C7CD"
                  />
                </svg>
                <span className="text-[#4B506D]">Trier</span>
              </div>
              <button
                className=" px-3 py-2 text-center text-[12px]  md:text-[16px] rounded text-white0 bg-blue1 "
                onClick={() => handleChooseCar(chosenCar)}
              >
                {customChooseButton ?? "Choose Car"}
              </button>
            </div>
          </div>
        )}
        <div
          className={`flex flex-col sm:hidden  ${
            isGenerateAdActive
              ? "h-[calc(100%-140px)]"
              : "h-[calc(100vh-280px)]"
          }  overflow-scroll`}
        >
          {/* Mobile View */}
          {filteredCars ? (
            //TODO:: adapt choose car for mobile display same logic as web
            <MobileCarDisplay
              loading={loading}
              carData={filteredCars}
              isGenerateAdActive={isGenerateAdActive}
              handleCheckbox={handleCheckbox}
              removeItem={removeCars}
              mode={mode}
              handleChooseCar={handleChooseCar}
            />
          ) : (
            <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
          )}
          {/* Mobile View */}

          {/* Web View */}
        </div>
        <div className="overflow-auto sm:flex flex-col hidden h-[calc(100%-150px)]">
          {filteredCars ? (
            <WebCarDisplay
              loading={loading}
              carData={filteredCars}
              isGenerateAdActive={isGenerateAdActive}
              setIsGenerateAdActive={setIsGenerateAdActive}
              removeItem={removeCars}
              mode={mode}
              chosenCar={chosenCar}
              handleChooseCar={preChooseCar}
            />
          ) : (
            <RiseLoader cssOverride={riseLoaderCss}></RiseLoader>
          )}
          {/* Web View */}
        </div>
        <BottomNav
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          totalNotifCount={totalCarsCount}
        ></BottomNav>
      </div>
    </>
  );
};
export default CarsDisplay;
